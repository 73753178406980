<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>巡检管理</a-breadcrumb-item>
            <a-breadcrumb-item>问题管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div
          v-if="!visible"
          class="flow-d-row"
          style="float: right; align-items: center"
        ></div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="!visible"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['state'].value"
                  v-if="i == '状态'"
                  placeholder="请选择状态"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 开启 </a-select-option>
                  <a-select-option value="1"> 关闭 </a-select-option>
                </a-select>
                <a-select
                  v-model="params['result'].value"
                  v-else-if="i == '处理状态'"
                  placeholder="请选择处理状态"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 未处理 </a-select-option>
                  <a-select-option value="1"> 已处理 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content style="background: #fff; padding: 24px; margin: 0">
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            childrenColumnName="child"
            :columns="columns"
            :data-source="problemlist"
            :rowClassName="rowClassName"
          >
            <span slot="result" slot-scope="text">
              {{ text == 0 ? '未处理' : '已处理' }}
            </span>
            <span slot="status" slot-scope="text">
              {{ text == 0 ? '开启' : '关闭' }}
            </span>
            <span style="font-family: '黑体'" slot="code" slot-scope="text"
              >{{ text }}
            </span>
            <template slot="action" slot-scope="text, record">
              <a @click="onEdit(record)">查看</a>&nbsp;&nbsp;
              <a @click="onchangestate(record)">{{
                record.state == 0 ? '关闭' : '开启'
              }}</a>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="1"
            :total="total"
            @change="onpagesize"
          />
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content style="background: #fff; padding: 24px; margin: 0">
        <div class="title-top">基本信息</div>
        <a-descriptions style="padding-left: 150px" :column="2">
          <a-descriptions-item label="问题编号">
            {{ prolist.code }}
          </a-descriptions-item>
          <a-descriptions-item> </a-descriptions-item>
          <a-descriptions-item label="任务名称">
            {{ prolist.patrolTaskName }}
          </a-descriptions-item>
          <a-descriptions-item label="巡检路线">
            {{ prolist.place }}
          </a-descriptions-item>
          <a-descriptions-item label="提交人">
            {{ prolist.submitByName }}
          </a-descriptions-item>
          <a-descriptions-item label="提交时间">
            {{ prolist.submitTime | timr }}
          </a-descriptions-item>
          <a-descriptions-item label="处理人">
            {{ prolist.handleByName }}
          </a-descriptions-item>
          <a-descriptions-item label="地点">
            {{ prolist.patrolSiteName }}
          </a-descriptions-item>
          <a-descriptions-item label="问题描述">
            {{ prolist.description }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions
          style="padding-left: 150px"
          layout="vertical"
          :column="1"
        >
          <a-descriptions-item label="图片">
            <div class="clearfix">
              <a-upload
                @preview="handlePreview"
                list-type="picture-card"
                :file-list="prolist.submitAttach"
              >
              </a-upload>
              <a-modal
                width="750px"
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </a-descriptions-item>
        </a-descriptions>
        <div class="title-top">处理结果</div>
        <a-descriptions style="padding-left: 150px" :column="1">
          <a-descriptions-item label="处理结果">
            {{ prolist.result == 0 ? '未解决' : '已解决' }}
          </a-descriptions-item>
          <a-descriptions-item label="处理详情">
            {{ prolist.resultNote }}
          </a-descriptions-item>
          <a-descriptions-item label="处理人">
            {{ prolist.handleByName }}
          </a-descriptions-item>
          <a-descriptions-item label="处理时间">
            {{ prolist.handleTime | timr }}
          </a-descriptions-item>
        </a-descriptions>
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div save-draw>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import moment from 'moment'
const columns = [
  {
    title: '问题编号',
    dataIndex: 'code',
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '任务名称',
    dataIndex: 'patrolTaskName',
  },
  {
    title: '问题描述',
    dataIndex: 'description',
  },
  {
    title: '提交人',
    dataIndex: 'submitByName',
  },
  {
    title: '处理人',
    dataIndex: 'handleByName',
  },
  {
    title: '处理状态',
    dataIndex: 'result',
    scopedSlots: { customRender: 'result' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const column = [
  {
    title: '点位编号',
    dataIndex: 'site.code',
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '点位名称',
    dataIndex: 'site.name',
  },
  {
    title: '楼栋',
    dataIndex: 'site.building.name',
  },
  {
    title: '楼层',
    dataIndex: 'site.floor.name',
  },
  {
    title: '安装位置',
    dataIndex: 'site.place',
  },
  {
    title: '巡检时间',
    dataIndex: 'patrolTime',
  },
  {
    title: '二维码',
    dataIndex: 'site.id',
    scopedSlots: { customRender: 'qr' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
]
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
// import moment from "moment";
import http from '../../../http'
export default {
  data() {
    return {
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      column,
      previewVisible: false,
      columns,
      visible: false,
      labelCol: { span: 10 },
      wrapperCol: { span: 20 },
      title: '',
      textlist: [
        '问题编号',
        '任务名称',
        '提交人',
        '处理人',
        '处理状态',
        '状态',
      ],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      value: '',
      params: {
        current: 1,
        pageSize: 10,
        code: {
          value: '',
          op: '%like%',
        },
        patrol_task_name: {
          value: '',
          op: '%like%',
        },
        submit_by_name: {
          value: '',
          op: '%like%',
        },
        handle_by_name: {
          value: '',
          op: '%like%',
        },
        result: {
          value: undefined,
          op: '=',
        },
        state: {
          value: undefined,
          op: '=',
        },
      },
      total: 1,
      selectedRowKey: [],

      Id: '',
      problemlist: [],
      prolist: {
        submitAttach: [],
        code: '',
        description: '',
        submitTime: '',
        submitByName: '',
        place: '',
        patrolTaskName: '',
        handleByName: '',
        handleTime: '',
        resultNote: '',
        result: '',
        handleAttach: '',

        patrolSiteName: '',
      },
      form: {
        id: '',
      },
      e: '',
      rules: {
        duration: [
          {
            required: true,
            message: '巡检路线时长不能为空',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '巡检路线名称不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    timr(time) {
      if (time == null) {
        return ' '
      } else {
        let ti = time.split('T')
        return ti[0] + ' ' + ti[1]
      }
    },
  },
  methods: {
    onchangetime(e) {
      if (e == null) {
        this.params['patrol_date'].value = undefined
      } else {
        this.params['patrol_date'].value = moment(e).format('YYYY-MM-DD')
      }
    },
    handleSearch(e) {
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['code'].value = values.问题编号
        this.params['patrol_task_name'].value = values.任务名称
        this.params['submit_by_name'].value = values.提交人
        this.params['handle_by_name'].value = values.处理人
      })
      this.getpatrolproblem()
    },
    handleReset() {
      this.fom.resetFields()
      this.params['state'].value = undefined
      this.params['result'].value = undefined
    },
    toggle() {
      this.expand = !this.expand
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    onClose() {
      this.visible = false
      this.selectedRowKey = []
      this.selectedRows = []
      this.form = {
        id: '',
        projectId: '',
        name: '',
        code: undefined,
        duration: '',
        state: '',
        sites: [],
        siteIds: [],
      }
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.id
      this.title = '编辑'
      this.visible = true
      this.getpatrolproblemId(item.id)
    },
    onpagesize(e) {
      this.params.current = e
      this.getpatrolproblem()
    },
    // 删除
    // onDetele() {
    //   console.log(this.selectedRowKey);
    //   this.deletepatroltask(this.selectedRowKeys);
    // },
    // async deletepatroltask(id) {
    //   try {
    //     const res = await http.deletepatroltask(id);
    //     const { success, msg } = res.data;
    //     if (success) {
    //       this.$message.success("提交成功");
    //     } else {
    //       this.$message.warning(msg);
    //     }
    //     this.timer();
    //   } catch (ex) {
    //     this.$message.error(ex);
    //     console.log("error:" + ex);
    //   }
    // },
    //查
    async getpatrolproblem() {
      try {
        const res = await http.getpatrolproblem(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.problemlist = data.problems.records
          this.total = data.problems.total
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getpatrolproblemId(id) {
      try {
        const res = await http.getpatrolproblemId(id)
        const { success, data } = res.data
        if (success) {
          this.prolist.submitAttach = []
          //   console.log(data);
          this.prolist = {
            code: data.problem.code,
            place: data.problem.site.place,
            patrolTaskName: data.problem.patrolTaskName,
            submitTime: data.problem.submitTime,
            submitByName: data.problem.submitByName,
            description: data.problem.description,
            patrolSiteName: data.problem.patrolSiteName,
            handleByName: data.problem.handleByName,
            handleTime: data.problem.handleTime,
            resultNote: data.problem.resultNote,
            result: data.problem.result,
          }
          if (data.problem.handleAttach != null) {
            if (JSON.parse(data.problem.handleAttach).length > 0) {
              let b = []
              JSON.parse(data.problem.handleAttach).forEach(item => {
                b.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  uid: nanoid(),
                  status: 'done',
                  url: client.signatureUrl(item.name),
                })
              })
              this.prolist.handleAttach = b
            }
          }
          if (data.problem.submitAttach != null) {
            if (JSON.parse(data.problem.submitAttach).length > 0) {
              let a = []
              JSON.parse(data.problem.submitAttach).forEach(item => {
                a.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  uid: nanoid(),
                  status: 'done',
                  url: client.signatureUrl(item.name),
                })
              })
              this.prolist.submitAttach = a
            }
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.getpatrolproblemstate(e.id, this.form.state)
    },
    //状态
    async getpatrolproblemstate(id, state) {
      try {
        const res = await http.getpatrolproblemstate(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    onpagesizet(e) {
      this.sitparams.current = e
    },
    onpagesizetmo(e) {
      this.mositparams.current = e
      this.getpatrol()
    },
    timer() {
      return setTimeout(() => {
        this.getpatrolproblem()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getpatrolproblem()
    let users = JSON.parse(localStorage.getItem('store-userlist'))
    this.form.projectId = users.projectInfo.projectId
    // this.callback();
    this.getsts()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKey = selectedRowKeys
          //   console.log(this.selectedRowKey);
          console.log(selectedRows)
        },
      }
    },
  },
}
</script>
<style scoped>
span {
  display: inline-block;
}
.search-wrap {
  width: 100%;
  border-radius: 3px;
  position: relative;
  min-height: 600px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-upload-text {
  font-size: 13px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.ant-advanced-search-form .ant-form-item {
  height: 80px;
  margin-bottom: 15px;
}
.ant-form-item {
  height: 50px;
  margin-bottom: 15px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}
.a-col-span {
  display: inline-block;
  width: 24px;
  text-align: center;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.check-box-ro {
  background: #f3f7ff;
  border-radius: 4px;
  padding: 4px 6px;
  margin-right: 10px;
  margin-bottom: 6px;
  display: inline-block;
  font-size: 14px;
  align-items: center;
}
::v-deep .ant-popover-inner-content {
  padding: 5;
}
::v-deep .ant-form-item-label {
  text-align: right;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-upload-list-picture-card .ant-upload-list-item,
::v-deep .ant-upload-select-picture-card,
::v-deep .ant-upload-list-picture-card-container {
  width: 70px;
  height: 70px;
}
.ant-calendar-picker-input.ant-input {
  border-radius: 0;
}
.title-top {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;
  border-left: 3px solid #1890ff;
  color: #000;
  padding-left: 10px;
}
::v-deep .anticon-delete {
  display: none;
}
</style>
